@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body,
  #root {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    min-height: 100vh;
  }

  body {
    background-color: theme(colors.neutral.100);
  }
}

@layer components {
  [class*="btn-"] {
    display: flex;
    width: max-content;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: 2px solid black;
    font-weight: 600;
    border-radius: 1rem;
  }

  [class*="btn-"]:disabled {
    background-color: theme(colors.neutral.300) !important;
    border-color: theme(colors.neutral.300) !important;
    color: theme(colors.neutral.500) !important;
  }

  .btn-primary {
    background-color: theme(colors.primary.500);
    border-color: theme(colors.primary.500);
    color: white;
  }

  .btn-primary:hover {
    background-color: theme(colors.primary.600);
    border-color: theme(colors.primary.600);
  }

  .btn-error {
    background-color: theme(colors.error.500);
    border-color: theme(colors.error.500);
    color: white;
  }

  .btn-error:hover {
    background-color: theme(colors.error.600);
    border-color: theme(colors.error.600);
  }

  .svg-btn {
    position: relative;
    border-radius: 100%;
  }
  .svg-btn > * {
    position: relative;
    z-index: 1;
  }
  .svg-btn:after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 0;
    border-radius: inherit;
    transform: scale(0);
    transition: transform 0.3s;
  }
  .svg-btn:hover:after {
    transform: scale(1);
  }

  .tab {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 1rem;
    font-weight: 600;
    text-transform: capitalize;
  }

  .tab:hover,
  .tab[aria-selected="true"] {
    color: theme(colors.primary.500);
  }

  .tab-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0.25rem;
    background-color: theme(colors.primary.500);
    transition: all 0.3s;
  }

  .topbar-nav-link {
    position: relative;
    font-weight: 600;
    opacity: 0.8;
  }

  .topbar-nav-link:after {
    content: "";
    position: absolute;
    inset: 0;
    top: auto;
    height: 0.2rem;
    background-color: white;
    transition: transform 0.3s;
    transform: scaleX(0);
    transform-origin: left;
  }

  .topbar-nav-link:hover,
  .topbar-nav-link[aria-selected="true"] {
    opacity: 1;
  }

  .topbar-nav-link[aria-selected="true"]:after {
    transform: scaleX(1);
    transform-origin: right;
  }

  .discount-tag {
    text-transform: uppercase;
    height: max-content;
    width: max-content;
    font-size: small;
    font-weight: 600;
    padding: 0.5rem 1.5rem;
    border-radius: 1rem;
    background-color: theme(colors.error.500);
    color: white;
  }

  .discount-tag.active {
    background-color: theme(colors.success.500);
  }

  .toggle {
    position: relative;
    height: 28px;
    width: 48px;
    background-color: theme(colors.neutral.200);
    border-radius: 24px;
    transition: background-color 0.3s;
  }

  .toggle > span {
    position: absolute;
    top: 4px;
    left: 4px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: white;
    transition: transform 0.3s;
  }
  .toggle[aria-checked="true"] {
    background-color: theme(colors.primary.500);
  }

  .toggle[aria-checked="true"] > span {
    transform: translateX(100%);
  }
}

@layer utilities {
  .focused {
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 1px;
  }
}
